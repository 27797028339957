import { version as arcanaVersion } from '@setplex/arcana'
import { version as triaApiVersion } from '@setplex/tria-api'
import { version as triaAdapterVersion } from '@setplex/tria-api-adapter-nora'

// helper to check built-in versions
const orBuiltin = (version: string): string =>
  version === '__VERSION__' ? '#built-in' : version

const format = (version: string, commit: string, build: string) =>
  `${version}-${commit}-${build}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const eraVersion = ({ build, commit, version }: any = {}) =>
  format(version ?? 'unknown', commit ?? 'nocommit', build ?? 'unspecified')

export const version = __APP_VERSION__
export const versions = {
  tria: format(__APP_VERSION__, __VCS_COMMITHASH__, __BUILD_NUMBER__),
  arcana: orBuiltin(arcanaVersion),
  api: orBuiltin(triaApiVersion),
  adapter: orBuiltin(triaAdapterVersion),
  era: eraVersion(window.ERA_CONFIG?.era),
} as const
